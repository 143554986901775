(function() {
	'use strict';

	angular.module('utils.joinFilter', []).filter('join', joinFilter);

	joinFilter.$inject = [];
	function joinFilter() {
		return join;

		////////////////

		function join(collection, delimiter) {
			if (!angular.isArray(collection)) {
				return undefined;
			}
			delimiter = delimiter || ', ';
			return collection.join(delimiter);
		}
	}
})();
