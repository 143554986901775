(function() {
	angular.module('utils.abs.filter', []).filter('abs', filter);

	function filter() {
		return function(value) {
			var result = 0;
			if (angular.isNumber(value)) {
				result = Math.abs(value);
			} else if (angular.isString(value)) {
				result = Math.abs(Number(value));
			}
			// return isNaN(result) ? value : Math.round(result);
			return isNaN(result) ? value : result;
		};
	}
})();
