import * as WebFont from 'webfontloader';

/**
 * Requires Google Web Font Loader: https://github.com/typekit/webfontloader
 */

angular.module('utils.webfont', []).factory('WebFontLoader', [
	function() {
		var activeFonts = {};
		var active = false;

		var service = {
			load: function(params) {
				var loadObj = {
					active: function() {
						active = true;
					},
					fontactive: function(familyName) {
						activeFonts[familyName] = true;
					}
				};
				angular.forEach(params, function(value, key) {
					loadObj[key] = value;
				});
				WebFont.load(loadObj);
			},
			allFontsActive: function() {
				return active;
			},
			isFontActive: function(familyName) {
				return activeFonts[familyName] === true;
			}
		};

		return service;
	}
]);
