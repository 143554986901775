/**
 * Created by josh.tune on 5/14/2015.
 */
angular.module('utils.DeviceDetection', []).service('DeviceDetection', [
	'$window',
	function($window) {
		/**
		 * @return {boolean}
		 */
		this.IsMobileDevice = function() {
			return !!(this.IsAndroid() || this.IsIpad() || this.IsIphone() || this.IsWindowsMobileDevice());
		};

		/**
		 * @return {boolean}
		 */
		this.IsAndroid = function() {
			return $window.navigator.userAgent.toLowerCase().match(/(android)/g) ? true : false;
		};

		/**
		 * @return {boolean}
		 */
		this.IsIpad = function() {
			return $window.navigator.userAgent.toLowerCase().match(/(ipad)/g) ? true : false;
		};

		/**
		 * @return {boolean}
		 */
		this.IsIphone = function() {
			return $window.navigator.userAgent.toLowerCase().match(/(iphone)/g) ? true : false;
		};

		/**
		 * @return {boolean}
		 */
		this.IsChromeBook = function() {
			return $window.navigator.userAgent.toLowerCase().match(/(cros)/g) ? true : false;
		};

		/**
		 * @return {boolean}
		 */
		this.IsWindowsMobileDevice = function() {
			return $window.navigator.userAgent
				.toLowerCase()
				.match(/(windows phone 8.0|windows phone os|xblwp7|zunewp7|windows nt 6.[23]; arm;)/g)
				? true
				: false;
		};
	}
]);
