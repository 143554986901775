(function() {
	'use strict';

	angular.module('utils.urlParser', []).service('urlParser', UrlParser);

	UrlParser.$inject = [];
	function UrlParser() {
		this.parse = parse;

		////////////////

		function parse(url, strictMode) {
			return parseUri(url, strictMode);
		}

		// Based on parseUri 1.2.2 by Steven Levithan <stevenlevithan.com>.
		// See http://blog.stevenlevithan.com/archives/parseuri.
		function parseUri(str, strictMode) {
			var options = {
					key: [
						'source',
						'protocol',
						'authority',
						'userInfo',
						'user',
						'password',
						'host',
						'port',
						'relative',
						'path',
						'directory',
						'file',
						'query',
						'anchor'
					],
					q: {
						name: 'queryKey',
						parser: /(?:^|&)([^&=]*)=?([^&]*)/g
					},
					parser: {
						strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
						loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
					}
				},
				parts = options.parser[!!strictMode ? 'strict' : 'loose'].exec(str),
				uri = {},
				index = options.key.length;

			while (index--) {
				uri[options.key[index]] = parts[index] || '';
			}

			uri[options.q.name] = {};
			uri.query.replace(options.q.parser, function($0, $1, $2) {
				if ($1) {
					uri[options.q.name][$1] = $2;
				}
			});

			return uri;
		}
	}
})();
