(function() {
	'use strict';

	angular.module('utils.minutesToHoursFilter', []).filter('minutesToHours', minutesToHours);

	minutesToHours.$inject = ['$filter'];
	function minutesToHours($filter) {
		return minutesToHoursFilter;

		////////////////

		function minutesToHoursFilter(minutes, hoursAbbrev, minutesAbbrev, minutesPadding) {
			var hours = Math.floor(minutes / 60),
				mins = minutes % 60;

			return (
				$filter('number')(hours, 0) +
				(hoursAbbrev || 'h') +
				' ' +
				(mins < 10 ? minutesPadding || '' : '') +
				mins +
				(minutesAbbrev || 'm')
			);
		}
	}
})();
