(function() {
	angular
		.module('utils.localization', ['pascalprecht.translate', 'ngSanitize'])
		.config(config)
		.provider('Localize', provider)
		.filter('translateFormatString', filter)
		.filter('translateLanguage', languageFilter);

	config.$inject = ['$translateProvider'];
	function config($translateProvider) {
		$translateProvider.preferredLanguage('en').useSanitizeValueStrategy('sanitizeParameters');
	}

	provider.$inject = ['$translateProvider'];
	function provider($translateProvider) {
		this.translations = function(newTranslations) {
			angular.forEach(newTranslations, function(value, key) {
				$translateProvider.translations(key, value);
			});
		};

		this.$get = [
			'$translate',
			function($translate) {
				return {
					translate: function(translationId) {
						return translationId?.length > 0 ? $translate(translationId) : translationId;
					},
					translateInstant: function(translationId) {
						return translationId?.length > 0 ? $translate.instant(translationId) : translationId;
					}
				};
			}
		];
	}

	filter.$inject = ['$translate', '$sce'];
	function filter($translate, $sce) {
		var translations = {};

		var that = this;
		return function(translationId) {
			// Create a unique ID to cache each translation, based on
			// translationId and arguments
			var key = _.map(arguments, function(arg) {
				return _.str.slugify(arg);
			}).join('_');

			// If it's already in the cache, just return it
			if (_.has(translations, key)) {
				return translations[key];
			}

			var args = [];
			if (arguments.length > 1) {
				args = _.tail(arguments);
			}

			var translation = translationId?.length > 0 ? $translate.instant(translationId) : translationId;

			// Add translation as our first parameter for the
			// sprintf function
			args.unshift(translation);

			// Format translated string, add it as trusted HTML so
			// Angular doesn't complain, then add it to the cache.
			translations[key] = $sce.trustAsHtml(_.str.sprintf.apply(that, args)).toString();

			return translations[key];
		};
	}

	languageFilter.$inject = ['$translate'];
	function languageFilter($translate) {
		return function(translationId, language) {
			return translationId?.length > 0 ? $translate.instant(translationId, {}, undefined, language || 'en') : translationId;
		};
	}
})();
