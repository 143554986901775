(function(window, angular, _, undefined) {
	angular.module('utils.vsHelper', []).directive('vsHelper', vsHelperDirective);

	vsHelperDirective.$inject = [];
	function vsHelperDirective() {
		return {
			link: function(scope, elem, attrs) {
				var searchBox = elem.find('input');
				if (searchBox) {
					searchBox.on('input', function() {
						scope.$broadcast('vsRepeatTrigger');
					});
				}
			}
		};
	}
})(window, window.angular, window._);
