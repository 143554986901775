(function() {
	'use strict';

	angular.module('utils.uuid', []).service('UUID', UUID);

	UUID.$inject = [];
	function UUID() {
		this.v4 = v4;

		////////////////

		/**
		 * Generates RFC4122 version 4 compliant UUID
		 *
		 * See: http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript/2117523#2117523
		 */
		function v4() {
			// jshint bitwise: false
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c === 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		}
	}
})();
