import * as lunr from 'lunr';

(function() {
	angular.module('utils.lunr.search', []).service('LunrSearchService', service);

	service.$inject = [];

	function service() {
		var $svc = this;

		$svc.lunrIndex = null;

		$svc.setupLunrIndex = setupLunrIndex;
		$svc.search = search;

		function setupLunrIndex(contents, fields, removeStemmer) {
			$svc.lunrIndex = lunr(function() {
				if (removeStemmer) {
					// Removing stemmer: https://github.com/olivernn/lunr.js/issues/377
					this.pipeline.remove(lunr.stemmer);
				}

				_.forEach(fields, field => {
					this.field(field);
				});

				_.forEach(contents, (content, index) => {
					this.add({
						// Each result object has a ref object with id value
						// id maps back to the index in the supplied data
						id: index,
						template: content.Template,
						title: content.Title
					});
				});
			});
		}

		function search(term) {
			var queryString = _.lowerCase(term);
			var results = [];

			if ($svc.lunrIndex) {
				// https://github.com/olivernn/lunr.js/issues/342#issuecomment-395191855
				results = $svc.lunrIndex.query(function(q) {
					q.term(lunr.tokenizer(queryString), { boost: 100, usePipeline: true });
					q.term(lunr.tokenizer(queryString), {
						boost: 10,
						usePipeline: false,
						wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING
					});
					q.term(lunr.tokenizer(queryString), { boost: 1, editDistance: 1 });
				});
			}
			return results;
		}
	}
})();
