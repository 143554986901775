import * as detect from 'detect.js';
window.detect = detect;

/**
 * Reads/builds links to activities.
 */
(function(undefined) {
	angular.module('utils.activityLinkHandler', ['ngStorage', 'utils.urlParser']).service('activityLinkHandler', activityLinkHandler);

	activityLinkHandler.$inject = ['$localStorage', '$http', '$log', '$q', '$window', 'ENV', 'urlParser'];
	function activityLinkHandler($localStorage, $http, $log, $q, $window, ENV, urlParser) {
		var siteCode;

		this.decodeQueryString = decodeQueryString;
		this.xmlToObject = xmlToObject;
		this.objectToXML = objectToXML;
		this.parseURL = parseURL;
		this.getAppURL = getAppURL;
		this.getAppQueryString = getAppQueryString;
		this.getNewAppQueryString = getNewAppQueryString;
		this.getHtml5ClientUrl = getHtml5ClientUrl;
		this.isChrome = isChrome;

		/**
		 * Private functions
		 */

		function httpGet(url, token) {
			return $http.get(url, { headers: { Authorization: 'bearer ' + token } });
		}

		function isEmptyOrWhiteSpace(str) {
			return !str || str === null || str.match(/^ *$/) !== null;
		}

		function getUser() {
			return $localStorage.user;
		}

		function getUserOrgId() {
			var user = getUser();
			if (!user) {
				return $q.when();
			}

			if (user.organizationIds && user.organizationIds.length) {
				return $q.when(user.organizationIds[0]);
			}

			return httpGet(ENV.managementApiUrl + '/v2.1/users/' + user.id, user.token).then(function(response) {
				var orgIds = (response.data && response.data.organizationIds) || [];
				return orgIds.shift();
			});
		}

		function getSiteCode() {
			// Already have site code cached, so just return it
			if (siteCode) {
				return $q.when(siteCode);
			}

			// Check local storage
			var user = getUser();

			// Don't have it, nor do we have any way of getting it
			if (!user) {
				return $q.when();
			}

			// User record in local storage has it, so cache it then return it
			if (user.siteCode) {
				siteCode = user.siteCode;
				if (ENV.siteCodeEnvironment) {
					siteCode += '@' + ENV.siteCodeEnvironment;
				}
				return $q.when(siteCode);
			}

			// Go to the API for the site code
			return getUserOrgId()
				.then(function(orgId) {
					if (!orgId) {
						return $q.when();
					}

					return httpGet(ENV.managementApiUrl + '/v2.1/organizations/' + orgId + '/siteCode', user.token);
				})
				.then(function(response) {
					if (!response || !response.data) {
						return $q.when();
					}

					siteCode = response.data.siteCode;
					if (ENV.siteCodeEnvironment) {
						siteCode += '@' + ENV.siteCodeEnvironment;
					}

					return $q.when(siteCode);
				});
		}

		/**
		 * Public functions
		 */

		/** Detects browser info and returns true if Chrome */
		function isChrome() {
			var info = $window.detect.parse($window.navigator.userAgent),
				browser = info.browser.family;
			// return browser && !!(browser.match(/^chrome/i));
			return browser && browser === 'Chrome';
		}

		/** Decodes a query string to an object - all keys and lowercased */
		function decodeQueryString(query) {
			var ret = {};
			var items = query.replace(/^\?/, '').split(/&/);
			for (var i = 0; i < items.length; i++) {
				var bits = items[i].split(/=/);
				if (bits.length === 2) {
					ret[decodeURIComponent(bits[0])] = decodeURIComponent(bits[1]);
				} else if (bits.length === 1) {
					ret[decodeURIComponent(bits[0])] = null;
				}
			}

			return ret;
		}

		/** Simple no-nesting no tags alphanumeric XML parser. */
		function xmlToObject(xml) {
			var regex = /<(\w+)>(\w+)<\/\1>/g;
			var m,
				ret = {};
			while ((m = regex.exec(xml))) {
				ret[m[1]] = m[2];
			}
			return ret;
		}

		/** Takes a flat alphanumeric object and turns it into a rootless XML document */
		function objectToXML(data) {
			var ret = '';
			for (var k in data) {
				ret += '<' + k + '>' + data[k] + '</' + k + '>';
			}

			return ret;
		}

		/**
		 * Reads the given document.location object and returns and object of:
		 * { activity: xxyy, dataSet: zzww, activitySpecificData: {k/v pairs of activitySpecificData}}
		 *
		 * Special cases (dataSet and additonal are not included):
		 *   activity == 'activityMenu' opens the activity menu
		 *   activity == null drops the user at te student login (no acticity)
		 */
		function parseURL(urlToParse) {
			var ret = {
					activity: null,
					dataSet: null,
					activitySpecificData: null,
					playerArguments: null
				},
				baseUrl = urlParser.parse(ENV.unityLaunchUrl),
				url = urlParser.parse(urlToParse || $window.location.href),
				path = url.path.replace(new RegExp('^' + baseUrl.path), ''),
				search = url.query;

			if ((!path || path === '/') && url.anchor) {
				path = url.anchor;
			}

			if (!isEmptyOrWhiteSpace(path)) {
				//Matches the parts of /IntroToLiteralQuestions/10?Mode=Read&il_sitecode=SCHOOL
				//Subpatterns:          22222222222222222222222 44 6666666666666666666666666666
				var m = /^[\/]?((\w+)(\/(\w+))?)?(\?(.*))?$/.exec(path);

				if (m) {
					if (m[2] || m[6]) {
						ret.activitySpecificData = {};
						ret.playerArguments = {};
					}

					if (m[2]) {
						ret.activity = m[2];
						if (m[4]) {
							ret.dataSet = m[4];
						}
					}

					if (m[6]) {
						if (search) {
							search += '&';
						}
						search += m[6];
					}
				}
			}

			if (search) {
				var queryArgs = decodeQueryString(search);
				angular.forEach(queryArgs, function(value, property) {
					var propertyMatch = /^il_(.*)$/.exec(property);

					if (property === 'activityMenu') {
						ret.activity = 'activityMenu';
					} else if (property === 'activity') {
						ret.activity = value;
					} else if (property === 'dataset' || property === 'dataSet') {
						ret.dataSet = value;
					} else if (property === 'activitySpecificData') {
						ret.activitySpecificData = xmlToObject(value);
					} else if (property === 'useLatest' && value === 'true') {
						ret.useLatest = true;
					} else if (property === 'triggeredBy') {
						ret.triggeredBy = value;
					} else if (property === 'sitecode') {
						if (!ret.playerArguments) {
							ret.playerArguments = {};
						}
						ret.playerArguments.sitecode = value;
					} else if (propertyMatch) {
						if (!ret.playerArguments) {
							ret.playerArguments = {};
						}
						ret.playerArguments[propertyMatch[1]] = value;
					} else {
						if (!ret.activitySpecificData) {
							ret.activitySpecificData = {};
						}
						ret.activitySpecificData[property] = value;
					}
				});
			}

			if (ret.activity && ret.activity !== 'activityMenu') {
				ret.activitySpecificData = ret.activitySpecificData || {};
				ret.playerArguments = ret.playerArguments || {};
			}

			return ret;
		}

		/** Pass the results from parseURL into this to get an imaginelearning:// link. */
		function getAppURL(activityInfo) {
			function getUrl(code) {
				var url = 'imaginelearning://',
					args = [];

				if (!activityInfo.activity) {
					args.push('studentlogin');
				} else if (activityInfo.activity === 'activityMenu') {
					args.push('activityMenu');
				} else if (activityInfo.dataSet && activityInfo.activitySpecificData) {
					args.push('activity=' + encodeURIComponent(activityInfo.activity));
					args.push('dataSet=' + encodeURIComponent(activityInfo.dataSet));
					args.push('activitySpecificData=' + encodeURIComponent(objectToXML(activityInfo.activitySpecificData)));
				} else {
					args.push('studentlogin');
				}

				if (code) {
					args.push('sitecode=' + encodeURIComponent(code));
				}

				angular.forEach(activityInfo.playerArguments, function(value, key) {
					if (key === 'client' || (key === 'sitecode' && code)) {
						return true; // continue
					} else {
						args.push(key + '=' + encodeURIComponent(value));
					}
				});

				url += '?' + args.join('&');

				return url;
			}

			if (activityInfo.playerArguments && activityInfo.playerArguments.sitecode) {
				return $q.when(getUrl(activityInfo.playerArguments.sitecode));
			}

			return getSiteCode()
				.then(function(code) {
					return $q.when(getUrl(code));
				})
				['catch'](function(error) {
					$log.error(error);
					return $q.when(getUrl());
				});
		}

		/** Pass the results from parseURL into this to a query string the unity app can parse. */
		function getAppQueryString(activityInfo) {
			function getUrl(code) {
				var url;

				if (!activityInfo.activity) {
					url = '?studentlogin';
				} else if (activityInfo.activity === 'activityMenu') {
					url = '?activityMenu';
				} else {
					url =
						'?activity=' +
						encodeURIComponent(activityInfo.activity) +
						'&dataset=' +
						encodeURIComponent(activityInfo.dataSet) +
						'&activitySpecificData=' +
						encodeURIComponent(objectToXML(activityInfo.activitySpecificData));
				}

				var playerArgs = [];
				angular.forEach(activityInfo.playerArguments, function(value, key) {
					if (key === 'client' || (key === 'sitecode' && code)) {
						return true; // continue
					} else {
						playerArgs.push(key + '=' + encodeURIComponent(value));
					}
				});
				if (playerArgs.length) {
					url += '&' + playerArgs.join('&');
				}

				return url + (code ? '&sitecode=' + encodeURIComponent(code) : '');
			}

			if (activityInfo.playerArguments && activityInfo.playerArguments.sitecode) {
				return $q.when(getUrl(activityInfo.playerArguments.sitecode));
			}

			return getSiteCode()
				.then(function(code) {
					return $q.when(getUrl(code));
				})
				['catch'](function(error) {
					$log.error(error);
					return $q.when(getUrl());
				});
		}

		function getNewAppQueryString(activityInfo) {
			function getQueryString(code) {
				var query = [];

				if (activityInfo.activity && !/(activitymenu|studentlogin)/i.test(activityInfo.activity)) {
					query.push('activity=' + encodeURIComponent(activityInfo.activity));
				}
				if (activityInfo.dataSet) {
					query.push('dataset=' + encodeURIComponent(activityInfo.dataSet));
				}
				if (activityInfo.activitySpecificData) {
					angular.forEach(activityInfo.activitySpecificData, function(value, key) {
						query.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
					});
				}

				angular.forEach(activityInfo.playerArguments, function(value, key) {
					if (key === 'client' || (key === 'sitecode' && code)) {
						return true; // continue
					} else {
						query.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
					}
				});

				if (code) {
					query.push('sitecode=' + encodeURIComponent(code));
				}

				if (activityInfo.triggeredBy) {
					query.push('triggeredBy=' + encodeURIComponent(activityInfo.triggeredBy));
				}

				return query.length ? '?' + query.join('&') : '';
			}

			if (activityInfo.playerArguments && activityInfo.playerArguments.sitecode) {
				return $q.when(getQueryString(activityInfo.playerArguments.sitecode));
			}

			return getSiteCode()
				.then(function(code) {
					return getQueryString(code);
				})
				.catch(function(error) {
					$log.error(error);
					return getQueryString();
				});
		}

		function getHtml5ClientUrl(activityInfo) {
			var client = activityInfo && activityInfo.playerArguments && activityInfo.playerArguments.client;
			if (!client) {
				client = ENV.enabledDevelopmentFeatures.useNewHtml5Client || activityInfo.useLatest === true ? 'new' : 'legacy';
				// client = isChrome() ? 'new' : 'legacy';
			}

			if (client === 'new') {
				return getNewAppQueryString(activityInfo).then(function(query) {
					return ENV.newHtml5ClientLaunchUrl.replace(/\$/, '') + (query ? '/' + query : '');
				});
			}
			return getAppQueryString(activityInfo).then(function(query) {
				return ENV.html5ClientLaunchUrl.replace(/\/$/, '') + (query ? '/' + query : '');
			});
		}
	}
})();
