/**
 * $cacheFactory.Cache-compatible cache class that expires entries after a set time.
 * (Note: we don't want to replace the actual $cacheFactory with this: Some caches, such as html templates, should last
 * until the page is reloaded.)
 */
angular.module('utils.timed_cache', []).factory('TimedCache', [
	'$rootScope',
	'$http',
	'$interval',
	function($rootScope, $http, $interval) {
		var timedCache = {};

		/** Creates a new TimedCache with the given time-to-live (in ms) */
		var TimedCacheInstance = function(ttl) {
			this.ttl = ttl;
			this.data = {};
			this.timeouts = {};

			timedCache.instances.push(this);
		};

		// We implement this interface: https://docs.angularjs.org/api/ng/type/$cacheFactory.Cache
		TimedCacheInstance.prototype = {
			_clearTimeout: function(key) {
				if (this.timeouts[key]) {
					$interval.cancel(this.timeouts[key]);
					delete this.timeouts[key];
				}
			},

			put: function(key, value) {
				this.data[key] = value;

				this._clearTimeout(key);

				// NB: We use interval instrad of timeout because timeout will cause the Protractor tests to hang
				this.timeouts[key] = $interval(
					function() {
						delete this.data[key];
						delete this.timeouts[key];
					}.bind(this),
					this.ttl,
					1,
					false
				);
			},

			get: function(key) {
				return this.data[key];
			},

			remove: function(key) {
				delete this.data[key];
				this._clearTimeout(key);
			},

			removeAll: function() {
				for (var key in this.timeouts) {
					this._clearTimeout(key);
				}
				this.data = {};
			},

			destroy: function() {
				//we aren't a member of cacheFactory, so we can't remove ourself from it.
			},

			info: function() {
				return {
					id: null,
					size: Object.keys(this.data).length,
					ttl: this.ttl
				};
			}
		};

		timedCache.minutes = function(count) {
			return count * 60 * 1000;
		};

		/** Creates a new TimedCacheInstance, but garbles and returns it in such a way that Angular can't kill itself with it. */
		timedCache.create = function(ttl) {
			var instance = new TimedCacheInstance(ttl);
			var ret = {};

			// Bind all our functions because Angular doesn't correctly bind when calling us (such as get()).
			// Also, return ONLY functions so Angulr won't try to do stupid things like deep copy instance.data (which it does,
			// then it barfs when it finds weirds objects consumers have cached there.)
			// So, basically return an map of functions. Not an actual instance.
			for (var k in instance) {
				if (typeof instance[k] === 'function' && k[0] !== '_') {
					ret[k] = instance[k].bind(instance);
				}
			}

			return ret;
		};

		var defaultActions = {
			get: {
				method: 'GET'
			},
			save: {
				method: 'POST'
			},
			query: {
				method: 'GET',
				isArray: true
			},
			remove: {
				method: 'DELETE'
			},
			delete: {
				method: 'DELETE'
			}
		};

		//helper method for using a cache on a resource for gets
		timedCache.enableCaching = function(resourceActions, cacheOrTTL) {
			var cache;
			if (cacheOrTTL) {
				if (typeof cacheOrTTL === 'number') {
					cache = timedCache.create(cacheOrTTL);
				} else {
					cache = cacheOrTTL;
				}
			} else {
				cache = timedCache.create(15 * 1000);
			}

			function clearCachePassthrough(data, headersGetter) {
				timedCache.removeAll();
				return data;
			}

			for (var defaultActionName in defaultActions) {
				//also cache (and trigger cache clear) on default resource methods
				if (!resourceActions[defaultActionName]) {
					resourceActions[defaultActionName] = angular.copy(defaultActions[defaultActionName]);
				}
			}

			for (var actionName in resourceActions) {
				var action = resourceActions[actionName];
				if (
					(!action.method || action.method === 'GET' || action.method === 'HEAD' || action.method === 'OPTIONS') &&
					!action.cache
				) {
					//cache the results of this call
					action.cache = cache;
				} else {
					//trap all other API calls (PUT, POST, DELETE, etc.) to clear all the caches.
					//This automatically invalidates possibly stale caches, even across services
					//(such as deleting an org will show up in a user's org list)
					if (action.transformRequest) {
						action.transformRequest.push(clearCachePassthrough);
					} else {
						action.transformRequest = [clearCachePassthrough].concat($http.defaults.transformRequest);
					}
				}
			}

			return resourceActions;
		};

		timedCache.instances = [];
		/** Clears all cache data for all timedCache instances. */
		timedCache.removeAll = function() {
			for (var i = 0; i < timedCache.instances.length; i++) {
				timedCache.instances[i].removeAll();
			}
		};

		$rootScope.$on('utils.timed_cache.clear', function(event, params) {
			timedCache.removeAll();
		});

		return timedCache;
	}
]);
