(function() {
	angular
		.module('product.names', [])
		.constant('ApiProducts', {
			Enyay: 'Enyay',
			Lectura: 'Lectura',
			ILE: 'ILE',
			Spanish: 'Spanish',
			Reader: 'Reader',
			Galileo: 'Galileo'
		})
		.constant('Products', {
			Enyay: 'Imagine Lectura',
			Lectura: 'Imagine Lectura',
			ILE: 'Imagine Language & Literacy',
			Spanish: 'Imagine Espa\xF1ol',
			Reader: 'Imagine Reading',
			Galileo: 'Imagine Assessment: Galileo',
			None: 'None',
			Any: 'Any'
		})
		.filter('productName', filter)
		.filter('printProducts', printProducts);

	filter.$inject = ['Products'];

	printProducts.$inject = ['Products'];

	function filter(Products) {
		return function(value) {
			if (!value || !value.length) {
				return Products.None;
			}
			return Products[value] || '';
		};
	}

	function printProducts(Products) {
		return function(values) {
			return (values || [])
				.map(val => Products[val])
				.sort()
				.join(', ');
		};
	}
})();
