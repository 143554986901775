angular
	.module('utils.gradeLevel', ['utils.localization'])
	.config([
		'LocalizeProvider',
		function(LocalizeProvider) {
			LocalizeProvider.translations({
				en: {
					gradeLevels: {
						common: {
							PrePreK: 'Pre-Pre-Kindergarten',
							PreK: 'Pre-Kindergarten',
							Kindergarten: 'Kindergarten',
							First: '1st Grade',
							Second: '2nd Grade',
							Third: '3rd Grade',
							Fourth: '4th Grade',
							Fifth: '5th Grade',
							Sixth: '6th Grade',
							Seventh: '7th Grade',
							Eighth: '8th Grade',
							Ninth: '9th Grade',
							Tenth: '10th Grade',
							Eleventh: '11th Grade',
							Twelfth: '12th Grade',
							Other: 'Other',
							All: 'All Grades'
						},
						commonLong: {
							PrePreK: 'Pre-Pre-Kindergarten',
							PreK: 'Pre-Kindergarten',
							Kindergarten: 'Kindergarten',
							First: 'First Grade',
							Second: 'Second Grade',
							Third: 'Third Grade',
							Fourth: 'Fourth Grade',
							Fifth: 'Fifth Grade',
							Sixth: 'Sixth Grade',
							Seventh: 'Seventh Grade',
							Eighth: 'Eighth Grade',
							Ninth: 'Ninth Grade',
							Tenth: 'Tenth Grade',
							Eleventh: 'Eleventh Grade',
							Twelfth: 'Twelfth Grade',
							Other: 'Other',
							All: 'All Grades'
						},
						numeric: {
							PrePreK: '',
							PreK: 'Pre-K',
							Kindergarten: 'K',
							First: '1',
							Second: '2',
							Third: '3',
							Fourth: '4',
							Fifth: '5',
							Sixth: '6',
							Seventh: '7',
							Eighth: '8',
							Ninth: '9',
							Tenth: '10',
							Eleventh: '11',
							Twelfth: '12',
							Other: 'Other',
							All: 'All Grades'
						},
						short: {
							PrePreK: '',
							PreK: 'Pre-K',
							Kindergarten: 'K',
							First: 'Grade 1',
							Second: 'Grade 2',
							Third: 'Grade 3',
							Fourth: 'Grade 4',
							Fifth: 'Grade 5',
							Sixth: 'Grade 6',
							Seventh: 'Grade 7',
							Eighth: 'Grade 8',
							Ninth: 'Grade 9',
							Tenth: 'Grade 10',
							Eleventh: 'Grade 11',
							Twelfth: 'Grade 12',
							Other: 'Other',
							All: 'All Grades'
						},
						long: {
							PrePreK: 'Pre-Pre-Kindergarten',
							PreK: 'Pre-Kindergarten',
							Kindergarten: 'Kindergarten',
							First: 'Grade 1',
							Second: 'Grade 2',
							Third: 'Grade 3',
							Fourth: 'Grade 4',
							Fifth: 'Grade 5',
							Sixth: 'Grade 6',
							Seventh: 'Grade 7',
							Eighth: 'Grade 8',
							Ninth: 'Grade 9',
							Tenth: 'Grade 10',
							Eleventh: 'Grade 11',
							Twelfth: 'Grade 12',
							Other: 'Other',
							All: 'All Grades'
						},
						reverse: {
							PrePreK: 'PrePreK',
							'Pre-Kindergarten': 'PreK',
							Kindergarten: 'Kindergarten',
							'Grade 1': 'First',
							'Grade 2': 'Second',
							'Grade 3': 'Third',
							'Grade 4': 'Fourth',
							'Grade 5': 'Fifth',
							'Grade 6': 'Sixth',
							'Grade 7': 'Seventh',
							'Grade 8': 'Eighth',
							'Grade 9': 'Ninth',
							'Grade 10': 'Tenth',
							'Grade 11': 'Eleventh',
							'Grade 12': 'Twelfth',
							Other: 'Other',
							'All Grades': 'All'
						}
					}
				},

				sp: {
					gradeLevels: {
						long: {
							PrePreK: 'Pre pre kinder',
							PreK: 'Pre kinder',
							Kindergarten: 'Jardín de infancia',
							First: 'Grado 1',
							Second: 'Grado 2',
							Third: 'Grado 3',
							Fourth: 'Grado 4',
							Fifth: 'Grado 5',
							Sixth: 'Grado 6',
							Seventh: 'Grado 7',
							Eighth: 'Grado 8',
							Ninth: 'Grado 9',
							Tenth: 'Grado 10',
							Eleventh: 'Grado 11',
							Twelfth: 'Grado 12',
							Other: 'Otro',
							All: 'Todos los grados'
						}
					}
				}
			});
		}
	])
	.factory('GradeLevel', [
		'Localize',
		function gradeLevelFactory(Localize) {
			var gradeLevelService = {
				prePreK: 'PrePreK',
				preK: 'PreK',
				kindergarten: 'Kindergarten',
				first: 'First',
				second: 'Second',
				third: 'Third',
				fourth: 'Fourth',
				fifth: 'Fifth',
				sixth: 'Sixth',
				seventh: 'Seventh',
				eighth: 'Eighth',
				ninth: 'Ninth',
				tenth: 'Tenth',
				eleventh: 'Eleventh',
				twelfth: 'Twelfth',
				other: 'Other',
				all: 'All',

				getList: function() {
					return [
						this.preK,
						this.kindergarten,
						this.first,
						this.second,
						this.third,
						this.fourth,
						this.fifth,
						this.sixth,
						this.seventh,
						this.eighth,
						this.ninth,
						this.tenth,
						this.eleventh,
						this.twelfth,
						this.other
					];
				},

				getGradeLevelCommon: function(level) {
					return level === undefined ? '' : Localize.translateInstant('gradeLevels.common.' + level);
				},

				getGradeLevelNumber: function(level) {
					var grades = {
						PrePreK: -2,
						PreK: -1,
						Kindergarten: 0,
						First: 1,
						Second: 2,
						Third: 3,
						Fourth: 4,
						Fifth: 5,
						Sixth: 6,
						Seventh: 7,
						Eighth: 8,
						Ninth: 9,
						Tenth: 10,
						Eleventh: 11,
						Twelfth: 12,
						Other: 13
					};
					return level === undefined ? -2 : grades[level];
				},

				getGradeLevelFromNumber: function(level) {
					var gradeLevel,
						grades = {
							PrePreK: -2,
							PreK: -1,
							Kindergarten: 0,
							First: 1,
							Second: 2,
							Third: 3,
							Fourth: 4,
							Fifth: 5,
							Sixth: 6,
							Seventh: 7,
							Eighth: 8,
							Ninth: 9,
							Tenth: 10,
							Eleventh: 11,
							Twelfth: 12,
							Other: 13
						};

					level = parseInt(level) || 0;
					_.forEach(Object.keys(grades), function(grade) {
						if (grades[grade] === level) {
							gradeLevel = grade;
						}
					});

					return gradeLevel === undefined ? 'Other' : Localize.translateInstant('gradeLevels.long.' + gradeLevel);
				},

				getGradeLevelCommonLong: function(level) {
					return level === undefined ? '' : Localize.translateInstant('gradeLevels.commonLong.' + level);
				},

				getGradeLevelNumeric: function(level) {
					return level === undefined ? '' : Localize.translateInstant('gradeLevels.numeric.' + level);
				},

				getGradeLevelText: function(level) {
					return level === undefined ? '' : Localize.translateInstant('gradeLevels.short.' + level);
				},

				getLongGradeLevelText: function(level) {
					return level === undefined ? '' : Localize.translateInstant('gradeLevels.long.' + level);
				},

				getReverseGradeLevelText: function(level) {
					return level === undefined ? '' : Localize.translateInstant('gradeLevels.reverse.' + level);
				}
			};

			return gradeLevelService;
		}
	])
	.filter('gradeLevelCommon', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getGradeLevelCommon(input);
			};
		}
	])
	.filter('gradeLevelCommonLong', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getGradeLevelCommonLong(input);
			};
		}
	])
	.filter('gradeLevelNumber', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getGradeLevelNumber(input);
			};
		}
	])
	.filter('gradeLevelNumeric', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getGradeLevelNumeric(input);
			};
		}
	])
	.filter('gradeLevel', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getGradeLevelText(input);
			};
		}
	])
	.filter('gradeLevelLong', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getLongGradeLevelText(input);
			};
		}
	])
	.filter('reverseGradeLevel', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getReverseGradeLevelText(input);
			};
		}
	])
	.filter('gradeLevelFromNumber', [
		'GradeLevel',
		function(GradeLevel) {
			return function(input) {
				return GradeLevel.getGradeLevelFromNumber(input);
			};
		}
	])
	.filter('gradeLevelLongLanguage', [
		'$translate',
		function($translate) {
			return function(input, language) {
				return $translate.instant('gradeLevels.long.' + input, {}, undefined, language || 'en');
			};
		}
	]);
