angular
	.module('utils.client.detector', ['utils.unsupported.detector'])
	.constant('Clients', {
		ios: 'ios',
		kindle: 'kindle',
		android: 'android',
		html5: 'html5',
		firefox: 'firefox',
		unity: 'unity'
	})
	.service('ClientDetector', ClientDetectorService);

ClientDetectorService.$inject = ['$window', 'Clients', 'UnsupportedDetector'];
function ClientDetectorService($window, Clients, UnsupportedDetector) {
	this.getClient = getClient;

	////////////////////

	function getClient() {
		const info = UnsupportedDetector.getInfo();

		if (info.os.family === 'iOS') {
			return Clients.ios;
		}
		if (info.device && info.device.family === 'Kindle') {
			return Clients.kindle;
		}
		if (info.os.family === 'Android') {
			return Clients.android;
		}
		if (UnsupportedDetector.isHtml5ClientSupported()) {
			return Clients.html5;
		}
		if (info.browser.family === 'Firefox' && info.browser.major >= 52 && !$window.navigator.plugins['Unity Player']) {
			// Firefox dropped support for plugins as of Firefox 52.
			// However, the ESR version of Firefox 52 _does_ support plugins.
			// There is no way to detect whether or not it is the ESR version,
			// so we'll just look for the Unity Plugin and if it's not installed
			// assume it won't work.
			return Clients.firefox;
		}
		return Clients.unity;
	}
}
