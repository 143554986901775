/**
 * Builds a links to an activity.
 * This will expose itself as and Angular service if Angular is present, else as a window global.
 */
(function(global) {
	if (global.angular) {
		global.angular
			.module('utils.activitylink', ['utils.unsupported.detector', 'utils.activityLinkHandler'])
			.service('ActivityLink', ActivityLink)
			/**
			 * Here's a useful angular directive for activity links.
			 * The value should resolve to an array:
			 *  - The first selement is the activity name. If this is 'activityMenu' nothing else is needed.
			 *  - The second element is the data set id.
			 *  - The third (optinal) element is the activity specific data.
			 *
			 * Usage example: <a il-activity-link="['IntroToLiteralQuestions', 1]">Link test</a>
			 */
			.directive('ilActivityLink', activityLinkDirective);
	} else {
		global.ActivityLink = new ActivityLink();
	}

	function ActivityLink() {
		var self = this;

		/**
		 * Relative or absolute URL we can use for the web player. Change as desired.
		 *
		 * N.B.: This URL ends with a trailing slash because it will be redirected to such without the trailing '/'.
		 * Make sure any URL you use does not get a 3xx redirect because Opera
		 * will drop the '#foo' part off the URL and break the link when this happens.
		 */
		self.baseUrl = '/player/';

		self.getBaseUrl = getBaseUrl;
		self.ensureUrlHasTrailingSlash = ensureUrlHasTrailingSlash;
		self.getActivityURL = getActivityURL;
		self.activitySpecificToQueryString = activitySpecificToQueryString;
		self.getActivityMenuURL = getActivityMenuURL;
		self.setupLink = setupLink;
		self.setWindowRef = setWindowRef;
		self.isWindowClosed = isWindowClosed;
		self.sendMessageToWindow = sendMessageToWindow;
		self.registerForMessage = registerForMessage;
		self.receiveMessage = receiveMessage;
		self.focusOnClientWindow = focusOnClientWindow;

		self.windowRef = null;

		function getBaseUrl() {
			//use environment variables if available
			if (global.IL_PORTAL_ENV && global.IL_PORTAL_ENV.unityLaunchUrl) {
				self.baseUrl = self.ensureUrlHasTrailingSlash(global.IL_PORTAL_ENV.unityLaunchUrl);
			}

			return self.baseUrl;
		}

		function ensureUrlHasTrailingSlash(url) {
			var lastChar = url.substr(-1); // Selects the last character
			if (lastChar !== '/') {
				// If the last character is not a slash
				url = url + '/'; // Append a slash to it.
			}
			return url;
		}

		/**
		 * Gets the link for a given activity.
		 */
		function getActivityURL(activityName, dataSet, activitySpecificData, detector, useLatest, triggeredBy) {
			// activitySpecificData = activitySpecificData || '';

			var activitySpecificDataParams = '',
				json =
					activitySpecificData && activitySpecificData.length && self.activitySpecificToQueryString(activitySpecificData) === ''
						? JSON.parse(activitySpecificData)
						: undefined;
			if (json && angular.isObject(json)) {
				var keys = Object.keys(json);
				_.forEach(keys, function(key, i) {
					activitySpecificDataParams += [(i > 0 ? '&' : '?') + key, '=', json[key]].join('');
				});
			}

			// Android does not support deep linking, so we'll pass parameters on the query string
			if (detector) {
				var info = detector.getInfo();
				if (info.os.family === 'Android') {
					var query = [];
					query.push('activity=' + encodeURIComponent(activityName));
					if (dataSet) {
						query.push('dataSet=' + encodeURIComponent(dataSet));
					}
					if (activitySpecificData) {
						query.push('activitySpecificData=' + encodeURIComponent(activitySpecificData));
					}
					if (useLatest) {
						query.push('useLatest=true');
					}
					if (triggeredBy) {
						query.push('triggeredBy=' + encodeURIComponent(triggeredBy));
					}

					return self.getBaseUrl() + '?' + query.join('&');
				}
			}

			var uri = [
				self.getBaseUrl(),
				encodeURIComponent(activityName),
				'/',
				encodeURIComponent(dataSet),
				activitySpecificDataParams && activitySpecificDataParams.length
					? activitySpecificDataParams
					: activitySpecificData
					? '?' + self.activitySpecificToQueryString(activitySpecificData)
					: ''
			].join('');

			if (useLatest) {
				uri += uri.match(/\?/) ? '&useLatest=true' : '?useLatest=true';
			}

			if (triggeredBy) {
				uri += uri.match(/\?/) ? '&triggeredBy=' : '?triggeredBy=';
				uri += encodeURIComponent(triggeredBy);
			}

			return uri;
		}

		/** Simple no-nesting no tags alphanumeric XML parser -> query string */
		function activitySpecificToQueryString(xml) {
			var regex = /<(\w+)>(\w+)<\/\1>/g;
			var m,
				ret = '';
			while ((m = regex.exec(xml))) {
				if (ret) {
					ret += '&';
				}

				ret += m[1] + '=' + m[2];
			}
			return ret;
		}

		function getActivityMenuURL(detector) {
			// Android does not support deep linking, so we'll pass parameters on the query string
			if (detector) {
				var info = detector.getInfo();
				if (info.os.family === 'Android') {
					return self.getBaseUrl() + '?activityMenu';
				}
			}

			return self.getBaseUrl() + 'activityMenu';
		}

		/**
		 * Given a link for an activity, sets the given element up so that clicking it
		 * will launch the activity (or drop you on the app install page).
		 *
		 * @param {element} el <a> tag that should launch the link
		 * @param {string} url activity url, such as from getActivityMenuURL or getActivityURL
		 * @return
		 */
		function setupLink(el, url) {
			$(el).attr('href', url);
		}

		function setWindowRef(ref) {
			self.windowRef = ref;

			// register the parent window to receive a 'CloseMe' message from child
			self.registerForMessage(window);
		}

		function isWindowClosed() {
			if (!self.windowRef) {
				return true;
			} else {
				return self.windowRef.closed;
			}
		}

		function sendMessageToWindow(message) {
			var target =
				global.IL_PORTAL_ENV && global.IL_PORTAL_ENV.newHtml5ClientLaunchUrl
					? global.IL_PORTAL_ENV.newHtml5ClientLaunchUrl
					: 'https://testapp.imaginelearning.com';

			self.windowRef.postMessage(message, target);
		}

		function receiveMessage(event) {
			var origin =
				global.IL_PORTAL_ENV && global.IL_PORTAL_ENV.newHtml5ClientLaunchUrl
					? global.IL_PORTAL_ENV.newHtml5ClientLaunchUrl
					: 'https://testapp.imaginelearning.com';

			if (event.origin !== origin) {
				return;
			}

			if (event.data.toString() === 'CloseMe' && self.windowRef) {
				self.windowRef.close();
			}
		}

		function registerForMessage(window) {
			window.addEventListener('message', self.receiveMessage, false);
		}

		function focusOnClientWindow() {
			if (self.windowRef) {
				self.windowRef.focus();
			}
		}
	}

	activityLinkDirective.$inject = ['ActivityLink', 'UnsupportedDetector', 'activityLinkHandler'];

	function activityLinkDirective(activityLink, UnsupportedDetector, activityLinkHandler) {
		return {
			scope: {
				activity: '=ilActivityLink',
				useLatest: '=?',
				triggeredBy: '@'
			},
			restrict: 'A',
			link: link
		};

		function link(scope, el, attrs) {
			var parts = scope.activity;
			var url;

			if (parts[0] === 'activityMenu') {
				url = activityLink.getActivityMenuURL(UnsupportedDetector);
			} else {
				url = activityLink.getActivityURL(
					parts[0],
					parts[1],
					parts[2] || '',
					UnsupportedDetector,
					!!scope.useLatest || false,
					scope.triggeredBy
				);
			}

			activityLink.setupLink(el, url, null);

			if (scope.useLatest) {
				el[0].onclick = function() {
					if (activityLink.isWindowClosed()) {
						var windowRef = window.open(el[0].href, '_blank');
						activityLink.setWindowRef(windowRef);
					} else {
						var activityInfo = activityLinkHandler.parseURL(url);

						activityLinkHandler.getHtml5ClientUrl(activityInfo).then(function(clientUrl) {
							// var protocol;

							// if (ENV.html5ClientLaunchUrlProtocol === 'same') {
							// 	protocol = window.location.protocol;
							// } else {
							// 	protocol = (ENV.html5ClientLaunchUrlProtocol || 'http') + ':';
							// }
							// clientUrl = clientUrl.replace(/^http[s]?:/i, protocol);

							activityLink.focusOnClientWindow();
							activityLink.sendMessageToWindow(clientUrl);
						});
					}

					return false;
				};
			}
		}
	}
})(window);
