(function() {
	'use strict';

	angular.module('utils.slugify', []).filter('slugify', slugifyFilter);

	function slugifyFilter() {
		return slugify;

		////////////////

		function slugify(value) {
			return _.str.slugify(value);
		}
	}
})();
