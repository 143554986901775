(function() {
	'use strict';

	angular.module('utils.forceHttps', []).service('forceHttps', ForceHttps);

	ForceHttps.$inject = ['$location', '$rootScope', '$window'];
	function ForceHttps($location, $rootScope, $window) {
		var deregister;

		// Public functions
		this.enable = enable;
		this.disable = disable;

		// Private functions (exposed for testing)
		this._ = {
			onLocationChangeSuccess: onLocationChangeSuccess
		};

		////////////////

		function enable() {
			deregister = $rootScope.$on('$locationChangeSuccess', onLocationChangeSuccess);
			if ($location.protocol() === 'http') {
				onLocationChangeSuccess(null, $location.absUrl());
			}
		}

		function disable() {
			deregister && deregister();
		}

		function onLocationChangeSuccess(event, newUrl) {
			if (!/^https/i.test(newUrl)) {
				$window.location.replace(newUrl.replace(/^http/i, 'https'));
			}
		}
	}
})();
