(function() {
	'use strict';
	// adapted from https://github.com/sindresorhus/decamelize

	angular.module('utils.decamelize', []).filter('decamelize', decamelizeFilter);

	function decamelizeFilter() {
		return function(str, sep, transform) {
			if (typeof str !== 'string') {
				// throw new TypeError('Expected a string');
				return str;
			}

			sep = typeof sep === 'undefined' ? ' ' : sep;
			transform = typeof transform === 'undefined' ? 'lowercase' : transform;

			str = str.replace(/([a-z\d])([A-Z])/g, '$1' + sep + '$2').replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + sep + '$2');

			switch (transform) {
				case 'none':
					return str;

				case 'lowercase':
					return str.toLowerCase();

				case 'capitalized':
					return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

				case 'words':
					return str.charAt(0).toUpperCase() + str.slice(1);

				default:
					return str.toLowerCase();
			}
		};
	}
})();
