/**
 * Created by zach.lewis on 5/11/2015.
 */
angular.module('utils.QueryParameters', []).service('QueryParameters', [
	'$window',
	function($window) {
		this.getQueryValue = function(key) {
			var result = unescape(
				$window.location.search.replace(
					new RegExp('^(?:.*[&\\?]' + escape(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
					'$1'
				)
			);
			if (!result || 0 === result.length) {
				return null;
			}
			return result;
		};
	}
]);
