(function() {
	angular.module('helpers.percentages', []).service('PercentageHelpers', service);

	service.$inject = [];

	function service() {
		this.absolute = absolute;
		this.difference = difference;
		this.percentDifferenceWithIndicator = percentDifferenceWithIndicator;
		this.percentDifference = percentDifference;
		this.floatToPercentCapped = floatToPercentCapped;
		this.floatToPercent = floatToPercent;
		this.percentOfValueCapped = percentOfValueCapped;
		this.percentOfValue = percentOfValue;

		function absolute(value) {
			var result = 0;
			if (angular.isNumber(value)) {
				result = Math.abs(value);
			} else if (angular.isString(value)) {
				result = Math.abs(Number(value));
			}
			return isNaN(result) ? value : Math.round(result);
		}

		function difference(begin, end) {
			if (!begin || !end || begin === null || end === null) {
				return '--';
			}
			if (!angular.isNumber(begin)) {
				begin = Number(begin);
			}
			if (!angular.isNumber(end)) {
				end = Number(end);
			}
			return Math.round(end - begin);
		}

		function percentDifference(begin, end) {
			if (!begin || !end || begin === null || end === null) {
				return 0;
			}
			return parseFloat(((end - begin) / begin) * 100).toFixed(0);
		}

		function percentDifferenceWithIndicator(begin, end) {
			if (!begin || !end || begin === null || end === null) {
				return '--';
			}
			var difference = percentDifference(begin, end);
			var formattedDifference = Math.abs(difference) + ' %';
			return difference && difference === '0'
				? '--'
				: difference && difference > 0
				? '\u25B2 ' + formattedDifference
				: '\u25BC ' + formattedDifference;
		}

		function floatToPercentCapped(fNumber) {
			var width = parseFloat(fNumber * 100).toFixed(0);
			width = isNaN(width) ? 0 : width;
			width = width > 100 ? 100 : width;
			return width + '%';
		}

		function floatToPercent(fNumber) {
			var width = parseFloat(fNumber * 100).toFixed(0);
			return width + '%';
		}

		function percentOfValueCapped(part, value) {
			var width = parseFloat((part / value) * 100).toFixed(0);
			width = isNaN(width) ? 0 : width;
			width = width > 100 ? 100 : width || 0;
			return width + '%';
		}

		function percentOfValue(part, value) {
			// var percent = parseFloat((part / value) * 100).toFixed(0);
			// percent = isNaN(percent) ? 0 : percent;
			// return percent + '%';
			part = isNaN(part) ? parseFloat(part) : part;
			value = isNaN(value) ? parseFloat(value) : value;
			if (!part || !value) {
				return '0%';
			}
			var percent = (part / value) * 100;
			return Math.round(percent) + '%';
		}
	}
})();
